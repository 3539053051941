// reducer.js
import {
  GET_ROBOTS,
  GET_ROBOTS_ERROR,
  GET_ROBOTS_SUCCESS,
  GET_SETTINGS,
  GET_SETTINGS_ERROR,
  GET_SETTINGS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadingSettings: false,
  successSettings: false,
  errorSettings: false,
  MessageSettings: null,
  Settings: [],

  appSettingGetRobot: [],
  appSettingGetRobotMsg: null,
  appSettingGetRobotSuccess: false,
  appSettingGetRobotError: false,
  appSettingGetRobotLoading: false,
};
const GetSettings = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        loadingSettings: true,
        successSettings: false,
        errorSettings: null,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        successSettings: true,
        errorSettings: null,
        Settings: action.payload,
      };
      
    case GET_SETTINGS_ERROR:
      return {
        ...state,
        loadingSettings: false,
        successSettings: false,
        errorSettings: true,
        MessageSettings: action.payload,
      };
      
    //GET_ROBOTS
    case GET_ROBOTS:
      state = {
        ...state,
        appSettingGetRobotSuccess: false,
        appSettingGetRobotError: false,
        appSettingGetRobotLoading: true,
      };
      break;
    case GET_ROBOTS_SUCCESS:
      state = {
        ...state,
        appSettingGetRobot: action.payload,
        appSettingGetRobotMsg: null,
        appSettingGetRobotSuccess: true,
        appSettingGetRobotError: false,
        appSettingGetRobotLoading: false,
      };
      break;
    case GET_ROBOTS_ERROR:
      state = {
        ...state,
        appSettingGetRobot: null,
        appSettingGetRobotMsg: action.payload,
        appSettingGetRobotSuccess: false,
        appSettingGetRobotError: true,
        appSettingGetRobotLoading: false,
      };
      break;
    default:
      return state;
  }
};

export default GetSettings;
