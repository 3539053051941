// actions.js
import {
  GET_ROBOTS,
  GET_ROBOTS_ERROR,
  GET_ROBOTS_SUCCESS,
  GET_SETTINGS,
  GET_SETTINGS_ERROR,
  GET_SETTINGS_SUCCESS,
} from "./actionTypes";

export const getSettings = () => ({
  type: GET_SETTINGS,
});

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsError = (error) => ({
  type: GET_SETTINGS_ERROR,
  payload: error,
});

//getRobot
export const getRobot = () => {
  return {
    type: GET_ROBOTS,
  };
};

export const getRobotSuccess = (robot) => {
  return {
    type: GET_ROBOTS_SUCCESS,
    payload: robot,
  };
};

export const getRobotError = (robot) => {
  return {
    type: GET_ROBOTS_ERROR,
    payload: robot,
  };
};
