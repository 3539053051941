// actions.js
import {
  COLLECTION_NFTS_DETAILS,
  COLLECTION_NFTS_DETAILS_ERROR,
  COLLECTION_NFTS_DETAILS_RESET,
  COLLECTION_NFTS_DETAILS_SUCCESS,
} from "./actionTypes";

export const postCollectionNftsDetails = (collectionId) => {
  return {
    type: COLLECTION_NFTS_DETAILS,
    payload: collectionId,
  };
};

export const postCollectionNftsDetailsSuccess = (collectionId) => {
  return {
    type: COLLECTION_NFTS_DETAILS_SUCCESS,
    payload: collectionId,
  };
};

export const postCollectionNftsDetailsError = (collectionId) => {
  return {
    type: COLLECTION_NFTS_DETAILS_ERROR,
    payload: collectionId,
  };
};

export const postCollectionNftsDetailsReset = () => {
  return {
    type: COLLECTION_NFTS_DETAILS_RESET,
  };
};
