import {
  EnumToastType,
  chainList,
  walletType,
} from "@/components/constants/layout";
import store from "@/store";
import {
  bsc,
  bscTestnet,
  mainnet,
  polygon,
  polygonAmoy,
  sepolia,
} from "@wagmi/core/chains";
// import { EthereumClient } from "@web3modal/ethereum";
// import { getNetwork, switchNetwork } from "@wagmi/core";
import { walletConnect } from "@wagmi/connectors";
import { createConfig, getAccount, http, switchChain } from "@wagmi/core";
import {
  // EIP6963Connector,
  createWeb3Modal,
} from "@web3modal/wagmi";
import { isEmpty } from "lodash";
// import { publicProvider } from "wagmi/providers/public";
import {
  clickHandleConnectBinance,
  clickHandleConnectMetamask,
  clickHandleConnectSui,
  clickHandleConnectWalletConnect,
  clickHandleConnectWeb3Auth,
  logStage,
  raiseToastMessage,
} from "@/components/common/utiles";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
import { CommonPrivateKeyProvider } from "@web3auth/base-provider";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3Auth } from "@web3auth/modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import {
  metadataWalletConnect,
  stage_env,
  web3AuthClientId,
  web3Configs,
} from "../config";
import { setItem, setItemCookie } from "./api_helper";
import { currentUser, initWeb3Auth, web3AuthProvider } from "./web3";

var privateKeyProvider;

export var stage = stage_env.stage; //stage1 | stage2 | (stage3 / production)
var chains;
if (
  process.env.NEXT_PUBLIC_URL.toLowerCase().includes("seed.photo") ||
  process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3")
) {
  chains = [bsc, mainnet, polygon];
} else if (process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage2")) {
  chains = [bsc, mainnet, polygon];
} else {
  chains = [bscTestnet, sepolia, polygonAmoy];
}

const mystore = store.getState();
var settingDataStore = mystore.GetSettings.Settings;

//* WALLET CONNECT CONFIG --- START
export let projectId;
if (
  process.env.NEXT_PUBLIC_URL.toLowerCase().includes("seed.photo") ||
  process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3")
) {
  projectId = web3Configs.projectId_Main;
} else {
  projectId = web3Configs.projectId_stage;
}

// 2. Create wagmiConfig
// const { publicClient } = configureChains(chains, [
//   walletConnectProvider({ projectId }),
//   publicProvider(),
// ]);

//walletconnect-config
//wagmi - createConfig search
export const wagmiConfig = createConfig({
  // autoConnect: true,
  chains: chains,
  ssr: true,
  // connectors: w3mConnectors({ projectId, chains }),
  connectors: [
    walletConnect({
      projectId: projectId,
      metadata: metadataWalletConnect,
      qrModalOptions: {
        themeMode: "dark",
      },
      showQrModal: false,
    }),
    // new EIP6963Connector({ chains }),
    // injected({ shimDisconnect: true }),
  ],
  transports: {
    [chains[0].id]: http(chains[0].rpcUrls.default.http),
    [chains[1].id]: http(chains[1].rpcUrls.default.http),
    [chains[2].id]: http(chains[2].rpcUrls.default.http),
  },
  // publicClient,
});

// export const ethereumClient = new EthereumClient(wagmiConfig, chains);

export const web3Modal = createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId,
  featuredWalletIds: [
    "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4", //binance
    "aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588",
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
});

//* WALLET CONNECT CONFIG --- END

//?--------------------------------

//* WEB3 CONFIG --- START
function generateWeb3Config(settingDataStore) {
  var chain;

  if (
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage1") ||
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost")
  ) {
    chain = [
      {
        id: chainList.BSCTestnet,
        name: "BSC_Testnet",
        rpc: settingDataStore?.RpcUrl_Bsc,
        museum_ETHAddress: settingDataStore?.Wallet_Museum,
        nft_contract_address: settingDataStore?.Nft_Contract_Address_Bsc,
        nft_contract_abi: settingDataStore?.AbiContract_Bsc,
        nft_contract_address_new:
          settingDataStore?.Nft_Contract_Address_Bsc_New,
        nft_contract_abi_new: settingDataStore?.AbiContract_Bsc_New,
        seed_marketplace_contract_address:
          settingDataStore?.Seed_Marketplace_Contract_Address_Bsc,
        seed_marketplace_contract_abi:
          settingDataStore?.AbiMarketplaceContract_Bsc,
        bridge_contract_address: settingDataStore?.BridgeContract_Bsc,
        bridge_contract_abi: settingDataStore?.AbiBridge_Bsc,
        symbol: "BNB",
        marketPlaceOwner: settingDataStore?.MarketPlaceOwner,
        ipfsUrl: settingDataStore?.IpfsUrl,
        blockExplorerUrl: "https://testnet.bscscan.com/",
      },
      {
        id: chainList.EthereumTestnet,
        name: "Goerli_ETH_Testnet",
        rpc: settingDataStore?.RpcUrl_ETH,
        museum_ETHAddress: settingDataStore?.Wallet_Museum,
        nft_contract_address: settingDataStore?.Nft_Contract_Address_ETH,
        nft_contract_abi: settingDataStore?.AbiContract_ETH,
        nft_contract_address_new:
          settingDataStore?.Nft_Contract_Address_ETH_New,
        nft_contract_abi_new: settingDataStore?.AbiContract_ETH_New,
        seed_marketplace_contract_address:
          settingDataStore?.Seed_Marketplace_Contract_Address_ETH,
        seed_marketplace_contract_abi:
          settingDataStore?.AbiMarketplaceContract_ETH,
        bridge_contract_address: settingDataStore?.BridgeContract_ETH,
        bridge_contract_abi: settingDataStore?.AbiBridge_ETH,
        symbol: "ETH",
        marketPlaceOwner: settingDataStore?.MarketPlaceOwner,
        ipfsUrl: settingDataStore?.IpfsUrl,
        blockExplorerUrl: "https://sepolia.etherscan.io/",
      },
      {
        id: chainList.PolygonTestnet,
        name: "Mumbai_Polygon_Testnet",
        rpc: settingDataStore?.RpcUrl_Polygon,
        museum_ETHAddress: settingDataStore?.Wallet_Museum,
        nft_contract_address: settingDataStore?.Nft_Contract_Address_Polygon,
        nft_contract_abi: settingDataStore?.AbiContract_Polygon,
        nft_contract_address_new:
          settingDataStore?.Nft_Contract_Address_ETH_New,
        nft_contract_abi_new: settingDataStore?.AbiContract_ETH_New,
        seed_marketplace_contract_address:
          settingDataStore?.Seed_Marketplace_Contract_Address_Polygon,
        seed_marketplace_contract_abi:
          settingDataStore?.AbiMarketplaceContract_Polygon,
        bridge_contract_address: settingDataStore?.BridgeContract_Polygon,
        bridge_contract_abi: settingDataStore?.AbiBridge_Polygon,
        symbol: "MATIC",
        marketPlaceOwner: settingDataStore?.MarketPlaceOwner,
        ipfsUrl: settingDataStore?.IpfsUrl,
        blockExplorerUrl: null,
      },
    ];
  } else {
    chain = [
      {
        id: chainList.BSCMainnet,
        name: "BSC_Mainnet",
        rpc: settingDataStore?.RpcUrl_Bsc,
        museum_ETHAddress: settingDataStore?.Wallet_Museum,
        nft_contract_address: settingDataStore?.Nft_Contract_Address_Bsc,
        nft_contract_abi: settingDataStore?.AbiContract_Bsc,
        nft_contract_address_new:
          settingDataStore?.Nft_Contract_Address_Bsc_New,
        nft_contract_abi_new: settingDataStore?.AbiContract_Bsc_New,
        seed_marketplace_contract_address:
          settingDataStore?.Seed_Marketplace_Contract_Address_Bsc,
        seed_marketplace_contract_abi:
          settingDataStore?.AbiMarketplaceContract_Bsc,
        bridge_contract_address: settingDataStore?.BridgeContract_Bsc,
        bridge_contract_abi: settingDataStore?.AbiBridge_Bsc,
        symbol: "BNB",
        marketPlaceOwner: settingDataStore?.MarketPlaceOwner,
        ipfsUrl: settingDataStore?.IpfsUrl,
        blockExplorerUrl: "https://bscscan.com/",
      },
      {
        id: chainList.EthereumMainnet,
        name: "ETH_Mainnet",
        rpc: settingDataStore?.RpcUrl_ETH,
        museum_ETHAddress: settingDataStore?.Wallet_Museum,
        nft_contract_address: settingDataStore?.Nft_Contract_Address_ETH,
        nft_contract_abi: settingDataStore?.AbiContract_ETH,
        nft_contract_address_new:
          settingDataStore?.Nft_Contract_Address_ETH_New,
        nft_contract_abi_new: settingDataStore?.AbiContract_ETH_New,
        seed_marketplace_contract_address:
          settingDataStore?.Seed_Marketplace_Contract_Address_ETH,
        seed_marketplace_contract_abi:
          settingDataStore?.AbiMarketplaceContract_ETH,
        bridge_contract_address: settingDataStore?.BridgeContract_ETH,
        bridge_contract_abi: settingDataStore?.AbiBridge_ETH,
        symbol: "ETH",
        marketPlaceOwner: settingDataStore?.MarketPlaceOwner,
        ipfsUrl: settingDataStore?.IpfsUrl,
        blockExplorerUrl: "https://etherscan.io/",
      },
      {
        id: chainList.PolygonMainnet,
        name: "Polygon_Mainnet",
        rpc: settingDataStore?.RpcUrl_Polygon,
        museum_ETHAddress: settingDataStore?.Wallet_Museum,
        nft_contract_address: settingDataStore?.Nft_Contract_Address_Polygon,
        nft_contract_abi: settingDataStore?.AbiContract_Polygon,
        nft_contract_address_new:
          settingDataStore?.Nft_Contract_Address_ETH_New,
        nft_contract_abi_new: settingDataStore?.AbiContract_ETH_New,
        seed_marketplace_contract_address:
          settingDataStore?.Seed_Marketplace_Contract_Address_Polygon,
        seed_marketplace_contract_abi:
          settingDataStore?.AbiMarketplaceContract_Polygon,
        bridge_contract_address: settingDataStore?.BridgeContract_Polygon,
        bridge_contract_abi: settingDataStore?.AbiBridge_Polygon,
        symbol: "MATIC",
        marketPlaceOwner: settingDataStore?.MarketPlaceOwner,
        ipfsUrl: settingDataStore?.IpfsUrl,
        blockExplorerUrl: "https://polygonscan.com/",
      },
    ];
  }

  const configStructure = {
    Nft_PackageId_Sui: settingDataStore?.Nft_PackageId_Sui,
    Seed_Marketplace_Sui: settingDataStore?.Seed_Marketplace_Sui,
    ipfsUrl: settingDataStore?.IpfsUrl,
    wallet_LockAndEarn: settingDataStore?.Wallet_LockAndEarn,
    Wallet_Staking: settingDataStore?.Wallet_Staking,
  };

  //object
  chain.forEach((stage) => {
    configStructure[stage.id] = {
      name: stage.name,
      rpc: stage.rpc,
      museum_ETHAddress: stage.museum_ETHAddress,
      nft_contract_address: stage.nft_contract_address,
      nft_contract_abi: stage.nft_contract_abi,
      nft_contract_address_new: stage.nft_contract_address_new,
      nft_contract_abi_new: stage.nft_contract_abi_new,
      seed_marketplace_contract_address:
        stage.seed_marketplace_contract_address,
      seed_marketplace_contract_abi: stage.seed_marketplace_contract_abi,
      bridge_contract_address: stage.bridge_contract_address,
      bridge_contract_abi: stage.bridge_contract_abi,
      native_currency: {
        name: stage.symbol,
        decimals: 18,
        symbol: stage.symbol,
      },
      marketPlaceOwner: stage?.marketPlaceOwner,
      ipfsUrl: stage?.ipfsUrl,
      wallet_LockAndEarn: stage?.wallet_LockAndEarn,
      blockExplorerUrl: stage?.blockExplorerUrl,
    };
  });

  return configStructure;
}

function generateweb3AuthChainConfig() {
  var chain;

  if (stage === "stage1") {
    chain = [
      {
        id: chainList.BSCTestnet,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x61", // hex of 97
        rpcTarget: "https://rpc.ankr.com/bsc_testnet_chapel",
        displayName: "Binance SmartChain Testnet",
        blockExplorerUrl: "https://testnet.bscscan.com",
        ticker: "BNB",
        tickerName: "BNB",
        logo: "https://cryptologos.cc/logos/bnb-bnb-logo.png",
      },
      {
        id: chainList.PolygonTestnet,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x13882",
        rpcTarget: "https://rpc.ankr.com/polygon_amoy",
        displayName: "Polygon Amoy Testnet",
        blockExplorerUrl: "https://amoy.polygonscan.com/",
        ticker: "MATIC",
        tickerName: "MATIC",
        logo: "https://cryptologos.cc/logos/polygon-matic-logo.png",
      },
      {
        id: chainList.EthereumTestnet,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0xaa36a7",
        rpcTarget: "https://rpc.ankr.com/eth_sepolia",
        displayName: "Ethereum Sepolia Testnet",
        blockExplorerUrl: "https://sepolia.etherscan.io",
        ticker: "ETH",
        tickerName: "Ethereum",
        logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
      },
      {
        id: chainList.SuiChainTestnet,
        chainNamespace: CHAIN_NAMESPACES.OTHER,
        chainId: "fd2adfa8",
        rpcTarget: "https://fullnode.devnet.sui.io:443",
        displayName: "Sui Devnet",
        blockExplorerUrl: "https://suiexplorer.com/?network=devnet",
        ticker: "SUI",
        tickerName: "Sui",
        logo: "https://cryptologos.cc/logos/sui-sui-logo.png?v=029",
      },
    ];
  } else {
    chain = [
      {
        id: chainList.BSCMainnet,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x38", // hex of 56
        rpcTarget: "https://rpc.ankr.com/bsc",
        displayName: "Binance SmartChain Mainnet",
        blockExplorerUrl: "https://bscscan.com/",
        ticker: "BNB",
        tickerName: "BNB",
        logo: "https://cryptologos.cc/logos/bnb-bnb-logo.png",
      },
      {
        id: chainList.PolygonMainnet,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x89", // hex of 137, polygon mainnet
        rpcTarget: "https://rpc.ankr.com/polygon",
        displayName: "Polygon Mainnet",
        blockExplorerUrl: "https://polygonscan.com",
        ticker: "MATIC",
        tickerName: "MATIC",
        logo: "https://cryptologos.cc/logos/polygon-matic-logo.png",
      },
      {
        id: chainList.EthereumMainnet,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x1",
        rpcTarget: "https://rpc.ankr.com/eth",
        displayName: "Ethereum Mainnet",
        blockExplorerUrl: "https://etherscan.io",
        ticker: "ETH",
        tickerName: "Ethereum",
        logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
      },
      {
        id: chainList.SuiChainMainnet,
        chainNamespace: CHAIN_NAMESPACES.OTHER,
        chainId: "35834a8a",
        rpcTarget: "https://fullnode.mainnet.sui.io:443",
        displayName: "Sui Mainnet",
        blockExplorerUrl: "https://suiexplorer.com/",
        ticker: "SUI",
        tickerName: "Sui",
        logo: "https://cryptologos.cc/logos/sui-sui-logo.png?v=029",
      },
    ];
  }

  const chainConfigs = {};

  chain.forEach((stage) => {
    chainConfigs[stage.id] = {
      chainNamespace: stage.chainNamespace,
      chainId: stage.chainId,
      rpcTarget: stage.rpcTarget,
      displayName: stage.displayName,
      blockExplorerUrl: stage.blockExplorerUrl,
      ticker: stage.ticker,
      tickerName: stage.tickerName,
      logo: stage.logo,
    };
  });

  return chainConfigs;
}

export var config3;
config3 = !isEmpty(settingDataStore) && generateWeb3Config(settingDataStore);

export var web3AuthChainConfigs;
web3AuthChainConfigs = generateweb3AuthChainConfig();

console.log("web3AuthChainConfigs", web3AuthChainConfigs);

export function initialSettingsDataConfig(data) {
  config3 = generateWeb3Config(data);
  console.log("initialSettingsDataConfig", config3);
}
//* WEB3 CONFIG --- END

export var chainIdd = parseInt(
  typeof window !== "undefined" && localStorage.getItem("chainIdd")
    ? localStorage.getItem("chainIdd")
    : stage === "stage1"
    ? "97"
    : "56"
);

export function setChainId(value) {
  chainIdd = value;
}

export function validateInputAddresses(address) {
  // console.log(address.length);
  if (address !== null) {
    if (address.length === 66) {
      return /^(0x){1}[0-9a-fA-F]{64}$/i.test(address);
    } else {
      return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
    }
  } else {
    return false;
  }
}

export function validateSuiInputAddresses(address) {
  // console.log(address.length);
  return /^(0x){1}[0-9a-fA-F]{64}$/i.test(address);
}

export function sleep(sleepDuration) {
  var now = new Date().getTime();
  while (new Date().getTime() < now + sleepDuration) {
    /* Do nothing */
  }
}

export const formatBalance = (rawBalance) => {
  const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(4);
  return balance;
};

export function generateGUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const formatChainAsNum = (chainIdHex) => {
  const chainIdNum = parseInt(chainIdHex);
  return chainIdNum;
};

export function chainName(chainId) {
  const chainNum = formatChainAsNum(chainId);
  let chainSymbolText;
  if (chainNum === chainList.BSCMainnet || chainNum === chainList.BSCTestnet) {
    chainSymbolText = "BNB";
  } else if (
    chainNum === chainList.EthereumMainnet ||
    chainNum === chainList.EthereumTestnet
  ) {
    chainSymbolText = "ETH";
  } else if (
    chainNum === chainList.PolygonMainnet ||
    chainNum === chainList.PolygonTestnet
  ) {
    chainSymbolText = "MATIC";
  } else if (
    chainNum === chainList.SuiChainMainnet ||
    chainNum === chainList.SuiChainTestnet
  ) {
    chainSymbolText = "SUI";
  }

  return chainSymbolText;
}

export var checkSession = "";
export function setCheckSession(value) {
  checkSession = value;
}

var onChangeChainLock = false;
export async function onChangeChain(
  stringChainId,
  onChange = null,
  inTransaction = false
) {
  console.log(stringChainId);
  // initContractAndAddress();
  var chainId = parseInt(stringChainId);
  console.log(stringChainId, "0x" + chainId.toString(16));

  onChangeChainLock = true;

  let loginType = JSON.parse(localStorage.getItem("loginType"));
  // console.log(loginType);
  if (
    loginType === walletType.metamask ||
    (loginType === walletType.suiWallet && sessionStorage.getItem("isDesktop"))
  ) {
    // console.log(loginType);
    if (window.ethereum.networkVersion !== chainId.toString()) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: "0x" + chainId.toString(16), //'0x38'
            },
          ],
        });
        setChainIdStorge(chainId);
        // sleep(3000);
      } catch (err) {
        console.log(err);

        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: config3[chainId.toString()]["name"], //'BSC Mainnet',
                chainId: "0x" + chainId.toString(16), //'0x38',
                nativeCurrency: {
                  name: config3[chainId.toString()]["native_currency"]["name"],
                  decimals:
                    config3[chainId.toString()]["native_currency"]["decimals"],
                  symbol:
                    config3[chainId.toString()]["native_currency"]["symbol"],
                }, //{ name: 'BNB', decimals: 18, symbol: 'BNB' },
                rpcUrls: [config3[chainId.toString()]["rpc"]], //['https://bsc-dataseed.binance.org/']
                blockExplorerUrls: [
                  config3[chainId.toString()]["blockExplorerUrl"],
                ],
              },
            ],
          });
        } catch (err) {
          console.log(err);
          setChainIdStorge(chainIdd);
          if (
            chainId === chainList.SuiChainMainnet ||
            chainId === chainList.SuiChainTestnet
          ) {
            raiseToastMessage(
              "Invalid Wallet, please connect with sui wallet",
              3000,
              true,
              EnumToastType.Error
            );
            return false;
          }
        }
      }
    }
  } else if (
    loginType === walletType.walletConnect ||
    (loginType === walletType.suiWallet && sessionStorage.getItem("isMobile"))
  ) {
    // const walletChainId = getNetwork().chain.id;
    const userChain = getAccount(wagmiConfig);
    console.log("chains", chains);
    const walletChainId = chains.find(
      (chain) => chain.id === userChain.chainId
    );

    // console.log("walletChainId", walletChainId);
    // console.log(getAccount(wagmiConfig));
    // console.log(userChain);
    // console.log(walletChainId);
    // console.log(walletChainId.id);
    // console.log(chainId);
    if (walletChainId) {
      if (walletChainId.id !== chainId) {
        // alert("Please check your web3 wallet application NOW!");
        try {
          const result = await switchChain(wagmiConfig, {
            chainId: chainId,
            connector: currentUser,
          })
            .then((res) => {
              console.log(res);
              return true;
            })
            .catch((err) => {
              raiseToastMessage(
                `Unable to switch chain, please change your chain to ${getChainName(
                  walletChainId.id
                )} and login again`,
                6000,
                true,
                EnumToastType.Error,
                "chain-changed"
              );
              console.log(err);

              return false;
            });
          // sleep(3000);
          // console.log(network);
        } catch (error) {
          console.log(error);

          return false;
        }
      }
    } else {
      try {
        raiseToastMessage(
          "Wrong network, please change your network in your wallet mobile app",
          3000,
          true,
          EnumToastType.Error
        );
        const network = await switchChain(wagmiConfig, {
          chainId: chains[0].id,
          connector: currentUser,
        });
        sleep(3000);
        console.log(network);
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  } else if (
    loginType === walletType.binanceWallet &&
    sessionStorage.getItem("isMobile")
  ) {
    // const walletChainId = getNetwork().chain.id;
    const userChain = getAccount();
    console.log("chains", chains);
    const walletChainId = chains.find(
      (chain) => chain.id === userChain.chainId
    );

    console.log("walletChainId", walletChainId);
    console.log(getAccount());
    console.log(userChain);
    console.log(walletChainId);
    console.log(walletChainId.id);
    console.log(chainId);
    if (walletChainId) {
      if (walletChainId.id !== chainId) {
        // alert("Please check your web3 wallet application NOW!");
        try {
          const result = await switchChain({
            chainId: chainId,
            connector: currentUser,
          })
            .then((res) => {
              console.log(res);
              return true;
            })
            .catch((err) => {
              raiseToastMessage(
                `Unable to switch chain, please change your chain to ${getChainName(
                  walletChainId.id
                )} and login again`,
                6000,
                true,
                EnumToastType.Error,
                "chain-changed"
              );
              console.log(err);

              return false;
            });
          // sleep(3000);
          // console.log(network);
        } catch (error) {
          console.log(error);

          return false;
        }
      }
    } else {
      try {
        raiseToastMessage(
          "Wrong network, please change your network in your wallet mobile app",
          3000,
          true,
          EnumToastType.Error
        );
        const network = await switchChain(wagmiConfig, {
          chainId: chains[0].id,
          connector: currentUser,
        });
        sleep(3000);
        console.log(network);
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  } else if (loginType === walletType.web3Auth) {
    try {
      if (web3auth) {
        initWeb3Auth();
        var hexChainId = await web3AuthProvider.eth.getChainId();
        // console.log("min hexChainId", hexChainId);

        var chainIdUser = parseInt(hexChainId);
        if (chainIdUser !== chainId) {
          if (inTransaction) {
            raiseToastMessage(
              `Wrong chian, Your chain is ${getChainName(
                chainIdUser
              )}, and NFT in on ${getChainName(chainId)}`,
              4000,
              true,
              EnumToastType.Warning,
              "wrong-chain"
            );
          }

          await web3auth
            .addChain(web3AuthChainConfigs[chainId])
            .then((res) => {
              console.log("res", res, "0x" + chainId.toString(16));
            })
            .catch((err) => console.log(err));
          await web3auth
            .switchChain({ chainId: "0x" + chainId.toString(16) })
            .then((res) => {
              setCheckSession("0x" + chainId.toString(16));
              sessionStorage.setItem(
                "switchChain",
                "0x" + chainId.toString(16)
              );
              onChange();
              setTimeout(
                () => {
                  raiseToastMessage(
                    `Your network changed to ${getChainName(chainId)}`,
                    5000,
                    true,
                    EnumToastType.Info,
                    "chain-changed"
                  );
                },
                inTransaction ? 3000 : 500
              );

              setChainIdStorge(chainIdd);
            })
            .catch((err) => console.log(err));
        }

        // console.log("test switch", );
      }
    } catch (error) {
      console.log("switchChain", error);
    }
  }
  // setChainIdStorge(chainId);
}

export function getChainName(chainId) {
  let chainSymbolName;
  if (chainId === chainList.BSCMainnet || chainId === chainList.BSCTestnet) {
    chainSymbolName = "BSC (BNB)";
  } else if (
    chainId === chainList.EthereumMainnet ||
    chainId === chainList.EthereumTestnet
  ) {
    chainSymbolName = "Ethereum (ETH)";
  } else if (
    chainId === chainList.PolygonMainnet ||
    chainId === chainList.PolygonTestnet
  ) {
    chainSymbolName = "Polygon (MATIC)";
  } else if (
    chainId === chainList.SuiChainMainnet ||
    chainId === chainList.SuiChainTestnet
  ) {
    chainSymbolName = "Sui (SUI)";
  } else if (chainId === chainList.SeedToken) {
    chainSymbolName = "SEED";
  }

  return chainSymbolName;
}

export async function handleChangeChain(chain, isTransaction) {
  let loginType = JSON.parse(localStorage.getItem("loginType"));
  if (loginType === walletType.metamask) {
    await onChangeChain(
      chain,
      () => {
        clickHandleConnectMetamask();
      },
      isTransaction
    );
  } else if (loginType === walletType.walletConnect) {
    await onChangeChain(
      chain,
      () => {
        clickHandleConnectWalletConnect();
      },
      isTransaction
    );
  } else if (loginType === walletType.binanceWallet) {
    await onChangeChain(
      chain,
      () => {
        clickHandleConnectBinance();
      },
      isTransaction
    );
  } else if (loginType === walletType.web3Auth) {
    await onChangeChain(
      chain,
      () => {
        clickHandleConnectWeb3Auth();
      },
      isTransaction
    );
  } else if (loginType === walletType.suiWallet) {
    await onChangeChain(
      chain,
      () => {
        clickHandleConnectSui();
      },
      isTransaction
    );
  } else {
    await onChangeChain(chain);
  }
}

export function setChainIdStorge(chainId) {
  setItem("chainIdd", parseInt(chainId));

  chainIdd = parseInt(
    typeof window !== "undefined" && localStorage.getItem("chainIdd")
      ? localStorage.getItem("chainIdd")
      : stage === "stage1"
      ? "97"
      : "56"
  );
}

export function uint8ArrayToBase64(bytes) {
  let binary = "";
  let len = bytes?.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function removeLocalStorageAfterDisconnect() {
  console.log("selectBinanceWallet");
  localStorage.removeItem("sellectedLoginType");
  setItemCookie("authentication", "", true);
  localStorage.removeItem("authUser");
  localStorage.removeItem("b64Params");
  localStorage.removeItem("chainIdd");
  localStorage.removeItem("settingsData");
  localStorage.removeItem("referralAddress");
  localStorage.removeItem("wagmi.connected");
  localStorage.removeItem("wagmi.wallet");
  localStorage.removeItem("wagmi.store");
  localStorage.removeItem("@w3m/recent");
  localStorage.removeItem("wagmi.recentConnectorId");
  localStorage.removeItem("wagmi.walletConnect.requestedChains");
  localStorage.removeItem("@w3m/connected_connector");
  localStorage.removeItem("wc@2:core:0.3//subscription");
  localStorage.removeItem("wc@2:core:0.3//history");
  localStorage.removeItem("wc@2:client:0.3//session");
  localStorage.removeItem("wc@2:core:0.3//session");
  localStorage.removeItem("wc@2:core:0.3//expirer");
  localStorage.removeItem("wc@2:core:0.3//pairing");
  localStorage.removeItem("wc@2:universal_provider:/optionalNamespaces");
  localStorage.removeItem("wc@2:universal_provider:/namespaces");
  localStorage.removeItem(
    "-walletlink:https://www.walletlink.org:session:linked"
  );
  localStorage.removeItem("-walletlink:https://www.walletlink.org:version");
  localStorage.removeItem("wc@2:ethereum_provider:/chainId");
  localStorage.removeItem("wc@2:core:0.3//keychain");
  localStorage.removeItem("-walletlink:https://www.walletlink.org:session:id");
  localStorage.removeItem(
    "-walletlink:https://www.walletlink.org:session:secret"
  );
  localStorage.removeItem("wc@2:universal_provider:/sessionProperties");
  localStorage.removeItem("wagmi.cache");
  localStorage.removeItem("wagmi.requestedChains");
  localStorage.removeItem("wc@2:client:0.3//proposal");
  localStorage.removeItem("loginType");
  localStorage.removeItem("wc@2:ethereum_provider:/chainId");
  localStorage.removeItem("wc@2:core:0.3//keychain");
  localStorage.removeItem("wc@2:core:0.3//messages");
  localStorage.removeItem("WK__LAST_CONNECT_WALLET_NAME");
  localStorage.removeItem("wagmi.io.metamask.disconnected");
  localStorage.removeItem("loglevel:http-helpers");
  localStorage.removeItem("loglevel:openlogin");
  localStorage.removeItem("loglevel:web3auth-logger");
  localStorage.removeItem("loglevel:SecurePubSub");
  localStorage.removeItem("loglevel:broadcast-channel");
  localStorage.removeItem("openlogin_store");
}

//web3Auth
// export const web3AuthChainConfig = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   chainId: "0x61", // hex of 97
//   rpcTarget: "https://rpc.ankr.com/bsc_testnet_chapel",
//   displayName: "Binance SmartChain Testnet",
//   blockExplorerUrl: "https://testnet.bscscan.com",
//   ticker: "BNB",
//   tickerName: "BNB",
//   logo: "https://cryptologos.cc/logos/bnb-bnb-logo.png",
// };

// console.log(
//   "check chain web3AuthChainConfigs",
//   web3AuthChainConfigs[
//     isNaN(chainIdd)
//       ? stage === "stage1"
//         ? chainList.BSCTestnet
//         : chainList.BSCMainnet
//       : chainIdd
//   ]
// );

function setPrivateKeyProvider(chain = chainIdd) {
  try {
    if (
      chain === chainList.SuiChainTestnet ||
      chain === chainList.SuiChainMainnet
    ) {
      privateKeyProvider = new CommonPrivateKeyProvider({
        config: {
          chainConfig: web3AuthChainConfigs[chain],
        },
      });
    } else {
      privateKeyProvider = new EthereumPrivateKeyProvider({
        config: {
          chainConfig:
            web3AuthChainConfigs[
              isNaN(chain)
                ? stage === "stage1"
                  ? chainList.BSCTestnet
                  : chainList.BSCMainnet
                : chain
            ],
        },
      });
    }
  } catch (error) {
    console.error(error);
    removeLocalStorageAfterDisconnect();
    privateKeyProvider = new EthereumPrivateKeyProvider({
      config: {
        chainConfig:
          web3AuthChainConfigs[
            stage === "stage1" ? chainList.BSCTestnet : chainList.BSCMainnet
          ],
      },
    });
  }
}

setPrivateKeyProvider();

logStage("privateKeyProvider", privateKeyProvider);

export const web3auth = new Web3Auth({
  clientId:
    stage === "stage1" ? web3AuthClientId.TestNet : web3AuthClientId.MainNet,
  web3AuthNetwork:
    stage === "stage1"
      ? WEB3AUTH_NETWORK.SAPPHIRE_DEVNET
      : WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
  privateKeyProvider: privateKeyProvider,
  uiConfig: {
    appName: "Seed.Photo",
    primaryButton: " emailLogin",
    mode: "dark",
    loginGridCol: 2,
    uxMode: "redirect",
    // logoLight: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
    // logoDark: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
  },
});

const openloginAdapter = new OpenloginAdapter({
  adapterSettings: {
    uxMode: "redirect",
    loginConfig: {
      // Google login
      google: {
        verifier:
          stage === "stage1" ? "seed-aggregate-verifier" : "google-identifier",
        verifierSubIdentifier: stage === "stage1" ? "w3a-google-verifier" : "",
        typeOfLogin: "google",
        clientId:
          stage === "stage1"
            ? "48708853543-a9ndc8rt92n2rls6l54ejckiavgfo7ga.apps.googleusercontent.com"
            : "166435666256-c4de3at7c2kl96tugdl310t7b7tru78d.apps.googleusercontent.com", // Pass on the Google `Client ID` here
      },
      emailpasswordless: {
        verifier:
          stage === "stage1"
            ? "seed-aggregate-verifier"
            : "email-passwordless-identifier", // Pass the Verifier name here. eg. w3a-agg-example
        verifierSubIdentifier: stage === "stage1" ? "w3a-email-verifier" : "", // Pass the Sub-Verifier here. eg w3a-a0-email-passwordless
        typeOfLogin: stage === "stage1" ? "jwt" : "email_passwordless", // Pass the type of login provider. For Auth0, it's jwt and not Auth0.
        // clientId: "RCEWlJ4xdgJwRcnte7HS0n0yQVeadCLy",
      },
    },
  },
  privateKeyProvider,
});

web3auth.configureAdapter(openloginAdapter);

export async function W3A_Logout() {
  //web3Auth
  if (web3auth.provider && web3auth.connected) {
    await web3auth.logout();
  }
}
