// reducer.js
import {
  COLLECTION_NFTS_DETAILS,
  COLLECTION_NFTS_DETAILS_ERROR,
  COLLECTION_NFTS_DETAILS_RESET,
  COLLECTION_NFTS_DETAILS_SUCCESS,
} from "./actionTypes";

const initialState = {
  CollectionNftsLoading: false,
  CollectionNftsSuccess: false,
  CollectionNftsError: false,
  CollectionNfts: null,
  CollectionNftsMsg: null,
};

const CollectionNftsDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_NFTS_DETAILS:
      return {
        ...state,
        CollectionNftsLoading: true,
        CollectionNftsSuccess: false,
        CollectionNftsError: false,
      };
    case COLLECTION_NFTS_DETAILS_SUCCESS:
      return {
        ...state,
        CollectionNftsLoading: false,
        CollectionNftsSuccess: true,
        CollectionNftsError: false,
        CollectionNfts: action.payload,
        CollectionNftsMsg: null,
      };
    case COLLECTION_NFTS_DETAILS_ERROR:
      return {
        ...state,
        CollectionNftsLoading: false,
        CollectionNftsSuccess: false,
        CollectionNftsError: true,
        CollectionNfts: null,
        CollectionNftsMsg: action.payload,
      };

    case COLLECTION_NFTS_DETAILS_RESET:
      return {
        ...state,
        CollectionNftsLoading: false,
        CollectionNftsSuccess: false,
        CollectionNftsError: false,
        CollectionNftsMsg: null,
      };

    default:
      return state;
  }
};

export default CollectionNftsDetailsReducer;
