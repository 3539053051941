// saga.js
import { postCollectionNftsDetail } from "@/config/helpers/backend_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  postCollectionNftsDetailsError,
  postCollectionNftsDetailsSuccess,
} from "./action";
import { COLLECTION_NFTS_DETAILS } from "./actionTypes";

function* fetchCollectionNftsDetails(action) {
  try {
    const FormData = action.payload;
    const response = yield call(postCollectionNftsDetail, FormData);
    yield put(postCollectionNftsDetailsSuccess(response));
  } catch (error) {
    yield put(
      postCollectionNftsDetailsError(error.response.data.Errors[0].Message)
    );
  }
}

function* CollectionDetailsNftSaga() {
  yield takeEvery(COLLECTION_NFTS_DETAILS, fetchCollectionNftsDetails);
}

export default CollectionDetailsNftSaga;
