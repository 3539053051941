// saga.js
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { setItem } from "@/config/helpers/api_helper";
import { getRobotText, getSettings } from "@/config/helpers/backend_helper";
import {
  getRobotError,
  getRobotSuccess,
  getSettingsError,
  getSettingsSuccess,
} from "./action";
import { GET_ROBOTS, GET_SETTINGS } from "./actionTypes";

function* fetchSetingsData() {
  try {
    const response = yield call(getSettings);
    setItem("settingsData", response);
    // console.log("enums" , response);
    yield put(getSettingsSuccess(response));
  } catch (error) {
    yield put(getSettingsError(error));
  }
}

function* fetchGetRobot() {
  try {
    const response = yield call(getRobotText);
    yield put(getRobotSuccess(response));
  } catch (error) {
    yield put(getRobotError(error));
  }
}

export function* watchSettings() {
  yield takeEvery(GET_SETTINGS, fetchSetingsData);
  yield takeEvery(GET_ROBOTS, fetchGetRobot);
}

function* SettingsSaga() {
  yield all([fork(watchSettings)]);
}

export default SettingsSaga;
