import { api_stage } from "@/config/config";
import fileDownload from "js-file-download";
import {
  EnumConsoleType,
  EnumRoles,
  NotificationType,
  s3FolderTypes,
  TransactionType,
} from "../constants/layout";
import { getCookie } from "@/config/helpers/api_helper";
import dynamic from "next/dynamic";
import ReactDOM from "react-dom/client";
import { stage } from "@/config/helpers/web3Utiles";
import W3A_ConfirmTransactionModal from "./w3aConfirmTransactionModal";
import { SuiClient, getFullnodeUrl } from "@mysten/sui.js/client";
const ToastMessages = dynamic(
  () => import("@/components/common/toastMessages"),
  { ssr: false }
);

export function cloudFlareCdnCgi(width, quality, blur) {
  var cdnLink = `/cdn-cgi/image/width=${width},quality=${quality},blur=${
    blur || 0
  }/`;
  return cdnLink;
}

export function kAndMnumberFormatter(value, toggle) {
  var number = ``;
  if (value === undefined || value === null) {
    number = "-";
  } else if (value >= 1000000) {
    number =
      toggle === true
        ? `${(parseFloat(value) / 1000000)?.toFixed(2)} M`
        : `${parseFloat(value) / 1000000} M`;
  } else if (value >= 1000) {
    number =
      toggle === true
        ? `${(parseFloat(value) / 1000)?.toFixed(2)} K`
        : `${parseFloat(value) / 1000} K`;
  } else {
    number =
      toggle === true
        ? `${parseFloat(value)?.toFixed(2)}`
        : `${parseFloat(value)}`;
  }
  return number;
}

export function cloudFlareCdnCgiSeo(width, quality, height, blur) {
  var cdnLink = `/cdn-cgi/image/width=${width},${
    height ? `height=${height},fit=cover,` : ""
  }format=auto,dpr=1,quality=${quality}/`;
  return cdnLink;
}

export function handleMouseEvents(
  elements,
  isCreator = false,
  isButton = false
) {
  elements?.forEach(function (element) {
    element?.addEventListener("mouseover", function () {
      var card = this.closest(".item_card");
      var dataLink = card?.getAttribute("data-link");
      var elementLink = this.getAttribute("data-link");

      if (isCreator) {
        card?.setAttribute("href", elementLink);
        card?.setAttribute("target", "_blank");
      } else if (isButton) {
        card?.removeAttribute("href");
      }

      this.addEventListener("mouseout", function () {
        card?.setAttribute("href", dataLink);
        if (isCreator || isButton) {
          card?.setAttribute("target", "_self");
        }
      });
    });
  });
}

export const customStyles = {
  multiValue: (base) => ({
    display: "flex",
    border: "2px solid white",
    margin: "3px",
  }),
  multiValueLabel: (base) => ({}),
  MultiValueGeneric: (base) => ({}),
  multiValueRemove: () => ({}),

  option: (base, state) => ({
    padding: "10px",
    backgroundColor: state.isFocused ? "#8E793E" : null,
    color: state.isFocused ? "white" : null,
  }),

  control: (base, state) => ({
    display: "flex",
    padding: "5px",
  }),
  noOptionsMessage: () => ({}),
};

export const customStyles2 = {
  multiValue: (base) => ({
    ...base,
    display: "flex",
    border: state.isFocused ? "2px solid #8E793E" : "2px solid #353945",
    margin: "3px",
  }),
  input: (base) => ({
    ...base,
    color: "#717889",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#717889",
    fontSize: "16px",
  }),
  option: (base, state) => {
    const darkMode =
      document.documentElement.getAttribute("data-layout-mode") === "dark";
    const backgroundColor = state.isFocused
      ? "#8E793E"
      : darkMode
      ? "#23262f"
      : null;
    const color = state.isFocused ? "white" : darkMode ? "white" : null;

    return {
      padding: "10px",
      backgroundColor,
      color,
    };
  },
  control: (base, state) => ({
    ...base,
    display: "flex",
    padding: "5px",
    backgroundColor: null,
    color: state.isFocused ? "#767D8E" : null,
    border: state.isFocused ? "2px solid #8e793e" : "2px solid #b1b5c3",
    borderRadius: "4px",
    boxShadow: state.isFocused
      ? "0 0 0 2px rgba(142, 121, 62, 0.5)"
      : base.boxShadow,
    "&:hover": {
      border: "2px solid #8e793e",
    },
  }),
  menu: (base) => {
    const darkMode =
      document.documentElement.getAttribute("data-layout-mode") === "dark";

    return {
      ...base,
      zIndex: 4,
      backgroundColor: darkMode ? "#23262f" : "white",
    };
  },
};

export function downloadFromURL(
  element,
  filename,
  setIsDownload,
  downloadFromURL
) {
  let baseApi =
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage1") ||
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost")
      ? api_stage.API_URL_STAGE1
      : process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage2")
      ? api_stage.API_URL_STAGE2
      : process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3")
      ? api_stage.API_URL_STAGE3
      : api_stage.API_URL_MAIN;
  var link = `${baseApi}api/Nfts/DownloadArtwork?url=${element}&fileName=${filename}`;
  fetch(`${link}`, {
    headers: {
      Authorization: `Bearer ${
        getCookie("authentication") !== ""
          ? JSON.parse(getCookie("authentication")).Token
          : null
      }`,
    },
  })
    .then((r) => r.blob())
    .then((d) => {
      console.log(window.URL.createObjectURL(d));
      fileDownload(
        d,
        `artwork-${filename.replaceAll(" ", "-").toLowerCase()}.jpg`
      );
      return window.URL.createObjectURL(d);
    })
    .then(() => {
      setIsDownload(true);
      downloadFromURL(false);
      setTimeout(() => {
        setIsDownload(false);
      }, 5000);
    });
}

export function logStage(
  message,
  object = null,
  status = EnumConsoleType.Default
) {
  if (stage === "stage1") {
    if (status === EnumConsoleType.Error) {
      console.error(message, object);
    } else if (status === EnumConsoleType.Warning) {
      console.warn(message, object);
    } else if (status === EnumConsoleType.Info) {
      console.info(message, object);
    } else {
      console.log(message, object);
    }
  }
}

export function calcCurrencyToUSD(price, currencyPrice, decimalCount = 3) {
  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  }).format((price ?? 0) * currencyPrice);

  return formattedPrice;
}

export function formatDate(date) {
  const dateObject = new Date(date);
  const formattedDate = `${
    dateObject.getMonth() + 1
  }/${dateObject.getDate()}/${dateObject.getFullYear()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()} ${
    dateObject.getHours() >= 12 ? "PM" : "AM"
  }`;

  return formattedDate;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function normalizeStrings(text) {
  return text?.replaceAll("_", " ");
}

export function findHighestBid(data) {
  const transactions = data?.Nft?.Transactions.filter(
    (transaction) =>
      transaction.TransactionType === TransactionType.Auction_Bid &&
      transaction.CreatedAt >= data?.Nft?.UpdatedAt
  );

  const latestAuctionBid =
    transactions && transactions?.length > 0
      ? transactions?.sort((a, b) => b.CreatedAt - a.CreatedAt)[0]
      : null;

  return latestAuctionBid;
}

//Transfer role
export function checkUserHasTransferRole(userData) {
  var userRoles = userData && userData?.Roles.map((item) => item.Name);

  var checkTransferRole =
    userData &&
    userRoles.find(
      (item) => item.toLowerCase() === EnumRoles.TransferNFT.toLowerCase()
    );

  var hasTransferRole =
    checkTransferRole &&
    checkTransferRole.toLowerCase() === EnumRoles.TransferNFT.toLowerCase()
      ? true
      : false;
  return hasTransferRole;
}

//Artist role
export function checkUserHasArtistRole(userData) {
  var userRoles = userData && userData?.Roles.map((item) => item.Name);

  var checkArtistRole =
    userData &&
    userRoles.find(
      (item) => item.toLowerCase() === EnumRoles.Artist.toLowerCase()
    );
  var hasArtistRole =
    checkArtistRole &&
    checkArtistRole.toLowerCase() === EnumRoles.Artist.toLowerCase()
      ? true
      : false;
  return hasArtistRole;
}

//Curator role
export function checkUserHasCuratorRole(userData) {
  var userRoles = userData && userData?.Roles.map((item) => item.Name);

  var checkCuratorRole =
    userData &&
    userRoles.find(
      (item) => item.toLowerCase() === EnumRoles.Curator.toLowerCase()
    );
  var hasCuratorRole =
    checkCuratorRole &&
    checkCuratorRole.toLowerCase() === EnumRoles.Curator.toLowerCase()
      ? true
      : false;
  return hasCuratorRole;
}

//Collector role
export function checkUserHasCollectorRole(userData) {
  var userRoles = userData && userData?.Roles.map((item) => item.Name);

  var checkCollectorRole =
    userData &&
    userRoles.find(
      (item) => item.toLowerCase() === EnumRoles.Collector.toLowerCase()
    );
  var hasCollectorRole =
    checkCollectorRole &&
    checkCollectorRole.toLowerCase() === EnumRoles.Collector.toLowerCase()
      ? true
      : false;
  return hasCollectorRole;
}

//Communities role
export function checkUserHasCommunitiesRole(userData) {
  var userRoles = userData && userData?.Roles.map((item) => item.Name);

  var checkCommunitiesRole =
    userData &&
    userRoles.find(
      (item) => item.toLowerCase() === EnumRoles.Communities.toLowerCase()
    );
  var hasCommunitiesRole =
    checkCommunitiesRole &&
    checkCommunitiesRole.toLowerCase() === EnumRoles.Communities.toLowerCase()
      ? true
      : false;
  return hasCommunitiesRole;
}

//Admin role
export function checkUserHasAdminRole(userData) {
  var userRoles = userData && userData?.Roles.map((item) => item.Name);

  var checkAdminRole =
    userData &&
    userRoles.find(
      (item) => item.toLowerCase() === EnumRoles.Admin.toLowerCase()
    );
  var hasAdminRole =
    checkAdminRole &&
    checkAdminRole.toLowerCase() === EnumRoles.Admin.toLowerCase()
      ? true
      : false;
  return hasAdminRole;
}

export function checkUserIsOwnerShip(userData, nftData) {
  const isOwner =
    (nftData?.Ownerships?.some((x) => x?.UserId?.includes(userData?.Id)) &&
      nftData?.Ownerships?.length === 1) ||
    (nftData?.Nft?.Creator.Id === userData?.Id &&
      nftData?.Ownerships?.length > 1);

  return isOwner;
}

export function checkUserTransactionBids(userData, nftData) {
  const userBidTransactions =
    nftData &&
    nftData.Nft?.Transactions.filter(
      (x) =>
        x.FromUser?.Id === userData?.Id &&
        x.TransactionType === TransactionType.Auction_Bid
    ).sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));

  return userBidTransactions;
}

export function findLastListAuctionTransaction(nftData) {
  const nftLastListAuctionTransaction =
    nftData &&
    nftData.Nft?.Transactions.filter(
      (x) => x.TransactionType === TransactionType.List_NFT_Start_Auction
    )
      .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
      .shift();

  // console.log(
  //   "nftLastListAuctionTransaction utils",
  //   nftLastListAuctionTransaction
  // );

  return nftLastListAuctionTransaction;
}

export function findLastBidTransaction(nftData) {
  const nftLastBidTransaction =
    nftData &&
    nftData.Nft?.Transactions.filter(
      (x) => x.TransactionType === TransactionType.Auction_Bid
    ).sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))[0];

  return nftLastBidTransaction;
}

export const Download = (Id) => {
  let baseApi =
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage1") ||
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost")
      ? api_stage.API_URL_STAGE1
      : process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage2")
      ? api_stage.API_URL_STAGE2
      : process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3")
      ? api_stage.API_URL_STAGE3
      : api_stage.API_URL_MAIN;
  fetch(`${baseApi}api/Nfts/DownloadCertificate/${Id}`, {
    headers: {
      Authorization: `Bearer ${
        getCookie("authentication") !== ""
          ? JSON.parse(getCookie("authentication")).Token
          : null
      }`,
    },
  })
    .then((r) => r.blob())
    .then((d) => {
      console.log(window.URL.createObjectURL(d));
      fileDownload(d, "certificate.png");
      return window.URL.createObjectURL(d);
    })
    .then(() => {
      // setIsDownload(true);
      setTimeout(() => {
        // setIsDownload(false);
      }, 5000);
    });
};

export function checkAndSetUserName(user) {
  const username = user?.DisplayName
    ? user?.DisplayName
    : user?.FirstName !== null && user?.LastName !== null
    ? `${user?.FirstName} ${user?.LastName}`
    : user?.UserName;

  return username;
}

export function checkAndSetUserNameReferral(user) {
  const username = user?.ReferralDisplayName
    ? user?.ReferralDisplayName
    : user?.ReferralFirstName !== null && user?.ReferralLastName !== null
    ? `${user?.ReferralFirstName} ${user?.ReferralLastName}`
    : user?.ReferralUserName;

  return username;
}

export function raiseToastMessage(
  message,
  duration,
  showToast,
  type,
  classFinder = "seed-toast",
  toastPosition = "top-center"
) {
  if (document.querySelector(`.${classFinder}`)) {
    var toasts = document.querySelectorAll(`.${classFinder}`);
    toasts.forEach((item) => item.remove());
  }
  const toastContainer = document.querySelector("#All-popups");
  const newDiv = document.createElement("div");
  newDiv.classList.add(`${classFinder}`);

  const handleCloseToast = () => {
    showToast = false;
    if (document.querySelector(`.${classFinder}`)) {
      var toasts = document.querySelectorAll(`.${classFinder}`);
      toasts.forEach((item) => item.remove());
    }
  };
  if (toastContainer) {
    ReactDOM.createRoot(newDiv).render(
      <>
        <ToastMessages
          message={message}
          duration={duration}
          showToast={showToast}
          type={type}
          toastPosition={toastPosition}
          onClose={handleCloseToast}
        />
      </>
    );
    toastContainer.appendChild(newDiv);

    setTimeout(() => {
      showToast = false;
      if (document.querySelector(`.${classFinder}`)) {
        var toasts = document.querySelectorAll(`.${classFinder}`);
        toasts.forEach((item) => item.remove());
      }
    }, duration);
  }
}

export async function raiseWeb3AuthPreInvoiceModal({
  transactionData,
  openModal,
  onCloseModal,
  fromAddress,
  destinationAddress,
  actionName,
  chain,
  amount = null,
  messageTitle = null,
  messageText = "",
  onConfirm,
  onReject,
}) {
  const modalPortalDiv = document.getElementById("modalPrtal");
  const newDiv = document.createElement("div");
  ReactDOM.createRoot(newDiv).render(
    <>
      <W3A_ConfirmTransactionModal
        openModal={openModal}
        actionName={actionName}
        chain={chain}
        fromAddress={fromAddress}
        destinationAddress={destinationAddress}
        amount={amount}
        messageTitle={messageTitle}
        messageText={messageText}
        onConfirm={onConfirm}
        onReject={onReject}
      />
    </>
  );
  modalPortalDiv.appendChild(newDiv);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function trimSuiAddress(address) {
  const trimAddress = address?.slice(0, 16) + "..." + address?.slice(60);
  return trimAddress;
}

export function forceDiconnect() {
  var disconnectElement = document.getElementById("RsCvD");
  if (disconnectElement) {
    console.log(document.getElementById("RsCvD"));
    document.getElementById("RsCvD").click();
  }
}

export function clickHandleConnectMetamask() {
  var disconnectElement = document.getElementById("RsCvD_meta");
  if (disconnectElement) {
    console.log(document.getElementById("RsCvD_meta"));
    document.getElementById("RsCvD_meta").click();
  }
}

export function clickHandleConnectWalletConnect() {
  var disconnectElement = document.getElementById("RsCvD_wc");
  if (disconnectElement) {
    console.log(document.getElementById("RsCvD_wc"));
    document.getElementById("RsCvD_wc").click();
  }
}

export function clickHandleConnectBinance() {
  var disconnectElement = document.getElementById("RsCvD_binance");
  if (disconnectElement) {
    console.log(document.getElementById("RsCvD_binance"));
    document.getElementById("RsCvD_binance").click();
  }
}

export function clickHandleConnectWeb3Auth() {
  var disconnectElement = document.getElementById("RsCvD_w3a");
  if (disconnectElement) {
    console.log(document.getElementById("RsCvD_w3a"));
    document.getElementById("RsCvD_w3a").click();
  }
}

export function clickHandleConnectSui() {
  var disconnectElement = document.getElementById("RsCvD_sui");
  if (disconnectElement) {
    console.log(document.getElementById("RsCvD_sui"));
    document.getElementById("RsCvD_sui").click();
  }
}

export function setNotificationType(notificationTypeId) {
  switch (notificationTypeId) {
    case NotificationType.Accept_Photographer:
    case NotificationType.Following:
    case NotificationType.Referral_Joined:
    case NotificationType.Reject_Photographer:
      return s3FolderTypes.S3ProfileFolder;
    default:
      return s3FolderTypes.S3NftFolder;
  }
}

export function addSpaceToPascalCase(name) {
  return name
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
}

const keyStr =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const triplet = (e1, e2, e3) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

function randomColor(number) {
  return Math.floor(Math.random() * 256);
}

export const rgbDataURL = (r, g, b) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, randomColor(r), randomColor(g)) +
    triplet(randomColor(b), 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

export async function getSuiBalance(suiWallet) {
  const rpcUrl = getFullnodeUrl(suiWallet.chains[0].replace("sui:", ""));

  console.log("rpcUrl", rpcUrl);

  const client = new SuiClient({ url: rpcUrl });

  console.log("client", client);

  let coins = await client
    .getCoins({ owner: suiWallet.address })
    .then((res) => res)
    .catch((error) => console.log(error));

  const totalSum = sumBalances(coins.data);
  console.log("coins", coins.data);
  console.log("coins", totalSum);

  return totalSum;
}

function sumBalances(obj) {
  return Object.values(obj)
    .map((item) => Number(item.balance)) // Convert balance to number
    .reduce((acc, balance) => acc + balance, 0); // Sum the balances
}

export function checkCertificateExpire(expireDate) {
  const GiftExpireDate = new Date(expireDate);
  if (expireDate !== null) {
    if (GiftExpireDate.getTime() > Date.now()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

//TODO
export function getFormattedDateTime() {
  const now = new Date();
  // const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000); // Convert to UTC

  const HH = now.getUTCHours().toString().padStart(2, "0");
  const M = (now.getUTCMonth() + 1).toString(); // +1 because months are 0-based
  const yy = now.getUTCFullYear().toString().slice(-2);
  const dd = now.getUTCDate().toString().padStart(2, "0");

  return `${HH}${M}${yy}${M}${yy}${M}${HH}${dd}`;
}
