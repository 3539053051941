import { APIClient, getLoggedinUser } from "./api_helper";
import { APIClientTwitter } from "./twitter_api_helper";
import * as url from "./url_helpers";

const api = new APIClient();
const api_twitter_farm = new APIClientTwitter();

// Gets the logged in user data from local session
// export const getLoggedInUser = () => {
//   const user = localStorage.getItem("authUser");
//   if (user) return JSON.parse(user);
//   return null;
// };

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedinUser() !== null;
};

//Account
export const postAccountLogin = (data) =>
  api.create(url.POST_ACCOUNT_LOGIN, data);

export const getAccountProfile = (data) =>
  api.get(`${url.GET_ACCOUNT_PROFILE}/${data}`);

export const getUserProfile = (data) =>
  api.getWithNoToken(`${url.GET_ACCOUNT_PROFILE}/${data}`);

export const getCoverProfile = (UserName) =>
  api.getWithNoToken(`${url.GET_ACCOUNT_COVER_PROFILE}/${UserName}`);

export const getUserGallary = (coverType) =>
  api.get(`${url.GET_ACCOUNT_USER_GALLARY}/${coverType}`);

export const getAccountAssetPortfolio = (data) =>
  api.get(
    `${url.GET_ACCOUNT_ASSET_PORTFOLIO}?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`
  );

export const PostAccountEditProfile = (user) =>
  api.createMultiPart(url.POST_ACCOUNT_EDIT_PROFILE, user);

export const getHomeReferralDashboard = () =>
  api.get(url.GET_ACCOUNT_REFERRAL_DASHBOARD_ARTIST);

export const toggleFollow = (username) =>
  api.get(`${url.GET_ACCOUNT_TOGGLE_FOLLOW}/${username}`);

export const getGenerateRandomUsernames = (data) =>
  api.get(
    `${url.GET_ACCOUNT_GENERATE_RANDOM_USERNAME}?firstName=${data.firstName}&lastName=${data.lastName}`
  );

export const postAccountVerificationForm = (data) =>
  api.createMultiPart(url.POST_ACCOUNT_VERIFICATION_FORM, data);

export const getToggleFavorites = (data) =>
  api.get(`${url.GET_ACCOUNT_TOGGLE_FAVORITES}/${data}`);

export const getToggleFavoriteCollection = (data) =>
  api.get(`${url.GET_ACCOUNT_TOGGLE_FAVORITES_COLLECTION}/${data}`);

export const postAccountReportNft = (data) =>
  api.create(url.POST_ACCOUNT_REPORT_NFT, data);

export const postAccountCreateCollection = (data) =>
  api.createMultiPart(url.POST_ACCOUNT_CREATE_COLLECTION, data);

export const postAccountEditCollection = (data) =>
  api.createMultiPart(url.POST_ACCOUNT_EDIT_COLLECTION, data);

export const getAccountDeleteCollection = (data) =>
  api.get(`${url.GET_ACCOUNT_DELETE_COLLECTION}/${data}`);

export const postAccountAddNftCollection = (data) =>
  api.create(url.POST_ACCOUNT_ADD_NFT_COLLECTION, data);

export const getAccountDeleteNftCollection = (data) =>
  api.get(`${url.GET_ACCOUNT_DELETE_NFT_COLLECTION}/${data}`);

export const postAccountIncreaseCollectionVideoViewCount = (data) =>
  api.create(url.POST_ACCOUNT_INCREASE_COLLECTION_VIDEO_VIEW, data);

export const getAccountSyncEngagementLevel = () =>
  api.get(`${url.GET_ACCOUNT_SYNC_ENGAGEMENT_LEVEL}`);

export const postAccountRegisterFromLink = (data) =>
  api.create(url.POST_ACCOUNT_REGISTER_FORM_LINK, data);

export const getAccountInstagram = (UserName) =>
  api.get(`${url.GET_ACCOUNT_INSTAGRAM}/${UserName}`);

export const getAccountGetMyTransaction = (data) =>
  api.create(url.GET_ACCOUNT_MY_TRANSACTION, data);

export const getAccountCollectionNfts = (data) =>
  api.get(
    `${url.GET_ACCOUNT_COLLECTION_NFTS}?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}&UserId=${data.UserId}&ComponentTypes=${data.ComponentTypes}`
  );

export const getAccountUserCollections = () =>
  api.get(url.GET_ACCOUNT_USER_COLLECTIONS);

export const getAccountFollowings = (userName, data) =>
  api.create(`${url.GET_ACCOUNT_FOLLOWNIGS}/${userName}`, data);

export const getAccountFollowers = (userName, data) =>
  api.create(`${url.GET_ACCOUNT_FOLLOWERS}/${userName}`, data);

export const getAccountReferrals = (userName, data) =>
  api.create(`${url.GET_ACCOUNT_REFERRALS}/${userName}`, data);

export const postAccountReportUser = (data) =>
  api.create(url.POST_ACCOUNT_REPORT_USER, data);

export const getAccountSeoProfile = (data) =>
  api.getWithNoToken(`${url.GET_ACCOUNT_PROFILE_SEO}/${data}`);

export const getCollectionDetailsSEO = (data) =>
  api.getWithNoToken(`${url.GET_ACCOUNT_COLLECTION_DETAILS_SEO}/${data}`);

export const postCheckAvailableUserName = (data) =>
  api.create(`${url.POST_ACCOUNT_CHECK_USERNAME}?userName=${data.userName}`);

export const getAllBalanceWalletData = (data) =>
  api.get(`${url.GET_ACCOUNT_WALLET_BALANCE_USER}?walletAddress=${data}`);

export const postVerifyTwitter = (data) =>
  api.create(url.POST_VERIFY_TWITTER, data);

export const getUserCertificate = () => api.get(url.GET_USER_CERTIFICATE);

//SuiWallet
export const postAccountAddSuiWallet = (data) =>
  api.create(url.POST_ACCOUNT_ADD_SUI_WALLET, data);

//ETHWallet
export const postAccountAddEthWallet = (data) =>
  api.create(url.POST_ACCOUNT_ADD_ETH_WALLET, data);

export const postAccountMergeSuiWallet = (data) =>
  api.create(url.POST_ACCOUNT_MERGE_SUI_WALLET, data);

export const getAccountUserReferralPromotion = () =>
  api.get(url.POST_ACCOUNT_GET_USER_REFERRAL_PROMOTION);

export const getAccountCheckFollowUser = (data) =>
  api.get(`${url.GET_ACCOUNT_CHECK_FOLLOW_USER}/${data}`);

//Home
export const getHomeHeroSection = () =>
  api.getWithNoToken(url.GET_HOME_HERO_SECTION);

export const getHomeMintedNftCount = () =>
  api.get(url.GET_HOME_MINTED_NFT_COUNT);

export const getHomeTrendingArtWork = () =>
  api.get(url.GET_HOME_TRENDING_ARTWORK);

export const getHomeTopCurators = () =>
  api.getWithNoToken(url.GET_HOME_TOP_CURATORS);

export const getHomeFeaturedArtists = () =>
  api.get(url.GET_HOME_FEATURED_ARTISTS);

export const getHomeTopPhotographers = (pageNumber) =>
  api.get(`${url.GET_HOME_TOP_PHOTOGRAPHERS}/${pageNumber}`);

export const getHomeTopArtists = (data) =>
  api.create(url.GET_HOME_TOP_ARTISTS, data);

export const getHomeExclusiveCollections = () =>
  api.get(url.GET_HOME_EXCLUSIVE_COLLECTIONS);

export const postHomeExclusiveCollections = (data) =>
  api.create(url.POST_HOME_EXCLUSIVE_COLLECTIONS, data);

export const getHomeCommunities = () =>
  api.getWithNoToken(url.GET_HOME_COMMUNITIES);

export const getHomeMuseum = () => api.get(url.GET_HOME_MUSEUM);

export const getHomeNewListing = () => api.get(url.GET_HOME_NEW_LISTING);

export const getHomeTopCollectors = () =>
  api.getWithNoToken(url.GET_HOME_TOP_COLLECTORS);

export const getHomeRecentlySold = () => api.get(url.GET_HOME_RECENTLY_SOLD);

export const getHomeTestimonials = () => api.get(url.GET_HOME_TESTIMONIALS);

export const getHomeHotBids = () => api.get(url.GET_HOME_HOT_BIDS);

export const getHomeHotCollections = () =>
  api.get(url.GET_HOME_HOT_COLLECTIONS);

export const postHomeNewsLetters = (data) =>
  api.createMultiPart(url.POST_HOME_NEWSLETTERS, data);

export const getHomeNews = () => api.get(url.GET_HOME_NEWS);
export const getHomeMarketPlaceInfo = () =>
  api.get(url.GET_HOME_MARKET_PLACE_INFO);

export const postHomeAllCategories = (data) =>
  api.create(url.POST_HOME_ALL_CATEGORIES, data);

// Museum
export const getMuseumHeroSection = () => api.get(url.GET_MUSEUM_HERO_SECTION);
export const getMuseumAuction = () => api.get(url.GET_MUSEUM_AUCTION);
export const getMuseumCalendarSection = (data) =>
  api.get(`${url.GET_MUSEUM_CALENDAR_SECTION}?week=${data}`);
export const getMuseumUpcomings = () => api.get(`${url.GET_MUSEUM_UPCOMINGS}`);
export const getMuseumReleased = () => api.get(`${url.GET_MUSEUM_RELEASED}`);
export const getMuseumCurrentWeek = () =>
  api.get(`${url.GET_MUSEUM_CURRENTWEEK}`);

//notifications
export const getUserNotificationSetting = () =>
  api.get(url.GET_NOTIFICATION_USER_NOTIFICATION_SETTINGS);

export const postNotificationUpdateSettings = (data) =>
  api.create(url.POST_NOTIFICATION_UPDATE_SETTINGS, data);

export const getAllNotificationList = (data) =>
  api.create(url.POST_NOTIFICATION_NOTIFICATION_FILTER, data);

export const getMarkAllAsRead = () =>
  api.get(url.GET_NOTIFICATION_MARK_ALL_READ);

//OpenSeaNfts
export const getOpenSeaCollections = () =>
  api.getFetchWithNoToken(`${url.GET_OPENSEA_COLLECTIONS}`);
export const getOpenSeaCollectionNft = (collectionName) =>
  api.getFetchWithNoToken(
    `${url.GET_OPENSEA_COLLECTIONS_NFT}/${collectionName}`
  );
export const getOpenSeaNft = (contractAddress, tokenId) =>
  api.getFetchWithNoToken(
    `${url.GET_OPENSEA_NFT}/${contractAddress}/${tokenId}`
  );
export const getOpenSeaNftEvents = (contractAddress, tokenId) =>
  api.getFetchWithNoToken(
    `${url.GET_OPENSEA_NFT_EVENTS}/${contractAddress}/${tokenId}`
  );
export const getOpenSeaAccount = (ETHAddress) =>
  api.getFetchWithNoToken(`${url.GET_OPENSEA_ACCOUNTS}/${ETHAddress}`);

//Setting
export const getSettings = () => api.get(url.GET_SETTINGS);
export const getEnums = () => api.get(url.GET_ENUMS);
export const getCategories = () => api.get(url.GET_CATEGORIES);

export const getCountry = () => api.get(url.GET_COUNTRIES);
export const getProvinces = (data) =>
  api.get(`${url.GET_PROVINCES}?CountryId=${data}`);
export const getCities = (data) =>
  api.get(`${url.GET_CITIES}?ProvinceId=${data}`);
export const getRobotText = () => api.get(url.GET_ROBOT_TEXT);

//ShippingAddressNfts
export const postShippingAddress = (data) =>
  api.create(url.POST_SHIPPING_ADDRESS, data);

export const getShippingAddressDetails = (data) =>
  api.get(`${url.GET_SHIPPING_ADDRESS_DETAIL}?NftId=${data}`);

//Nfts
export const getNftCards = (data) =>
  api.get(
    `${url.GET_NFTS_NFT_CARD}?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}&UserId=${data.UserId}&NftId=${data.NftId}&CategoryId=${data.CategoryId}&RatioType=${data.RatioType}&ComponentTypes=${data.ComponentTypes}`
  );

export const getMuseumCardsProfile = (data) =>
  api.get(`${url.GET_ACCOUNT_MUSEUM_NFTS}/${data.id}`);

export const getNftDetail = (data) => api.get(`${url.GET_NFTS_DETAIL}/${data}`);
export const getNftDetailNoToken = (data) =>
  api.getWithNoToken(`${url.GET_NFTS_DETAIL}/${data}`);
export const getNftDetailStep2 = (data) =>
  api.get(`${url.GET_NFTS_DETAIL_STEP2}/${data}`);

export const getNftsInspire = (data) =>
  api.get(
    `${url.GET_NFTS_INSPIRE}?Category=${data.Category}&PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`
  );

export const getNftsExplore = (data) =>
  api.get(
    `${url.GET_NFTS_EXPLORE}?Feed=${data.Feed}&Categories=${data.Categories}&Search=${data.Search}&Type=${data.Type}&Sort=${data.Sort}&RatioType=${data.RatioType}&ChainId=${data.ChainId}&PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`
  );
// export const getNftsExploreNoToken = (data) =>
//   api.getWithNoToken(
//     `${url.GET_NFTS_EXPLORE}?Feed=${data.Feed}&Categories=${data.Categories}&Search=${data.Search}&Type=${data.Type}&Sort=${data.Sort}&RatioType=${data.RatioType}&ChainId=${data.ChainId}&PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`
//   );
export const getSearchNftsExplore = (data) =>
  api.get(`${url.GET_NFTS_EXPLORE}?Search=${data}`);

export const getNftToggleArchive = (data) =>
  api.get(`${url.GET_NFTS_TOGGLE_ARCHIVE}/${data}`);

export const getNftDeleteNft = (data) =>
  api.get(`${url.GET_NFTS_DELETE_NFT}/${data}`);

export const postUploadNft = (data) =>
  api.createMultiPart(url.POST_NFTS_UPLOAD, data);

export const postToggleLockNft = (data) =>
  api.create(url.POST_NFTS_TOGGLE_LOCK, data);

export const postNftReloadSyncContractTransaction = (data) =>
  api.create(`${url.POST_NFTS_RELOAD_SYNC_TRANSACTION}?chainId=${data}`);

export const getNftCheckLock = (data) =>
  api.get(`${url.GET_NFTS_CHECK_LOCK}/${data}`);

export const postBurnNft = (data) => api.create(url.POST_NFTS_BURN_NFT, data);

export const postTransferNft = (data, onSuccess) =>
  api.create2(url.POST_NFTS_TRANSFER_NFT, data, onSuccess);

export const postBridgeNft = (data) =>
  api.create(url.POST_NFTS_BRIDGE_NFT, data);

export const getNftUpdateMarketPlaceId = () =>
  api.get(url.GET_NFTS_UPDATE_MARKET_PLACE);

export const postListNft = (data, onSuccess) =>
  api.create2(url.POST_NFTS_LIST_NFT, data, onSuccess);

export const postUnListNft = (data, onSuccess) =>
  api.create2(url.POST_NFTS_UNLIST_NFT, data, onSuccess);

export const postFinishAuctionNft = (data, onSuccess) =>
  api.create2(url.POST_NFTS_FINISH_AUCTION, data, onSuccess);

export const postBuyNft = (data, onSuccess) =>
  api.create2(url.POST_NFTS_BUY_NFT, data, onSuccess);

export const postBidNft = (data, onSuccess) =>
  api.create2(url.POST_NFTS_BID_NFT, data, onSuccess);

export const getNFTCID = (data) =>
  api.get(`${url.GET_NFTS_NFTCID}?nftId=${data}`);

export const postLogMintNft = (data, onSuccess, onFailure) =>
  api.create2(url.POST_NFTS_LOG_MINT_NFT, data, onSuccess, onFailure);

export const getDownloadCertificateNft = (data) =>
  api.getBlob(`${url.GET_NFTS_DOWNLOAD_CERTIFICATE}/${data}`);

export const getCancelPromotionNft = (data) =>
  api.get(`${url.GET_NFTS_CANCEL_PROMOTION}/${data}`);

export const postGenerateAiDescription = (data) =>
  api.create(`${url.POST_NFTS_AI_DESCRIPTION}?title=${data}`);

export const postGenerateAiDescriptionShorten = (data) =>
  api.create(`${url.POST_NFTS_AI_DESCRIPTION_SHORTEN}?title=${data}`);

export const getNftOrderConfirmation = (data) =>
  api.get(`${url.GET_NFTS_ORDER_CONFIRMATION}/${data}`);

export const getNftNSFWs = (data) =>
  api.get(
    `${url.GET_NFTS_NSFW}?Category=${data.Category}&PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`
  );

export const getNftHistory = (data) =>
  api.create(`${url.POST_NFTS_GET_NFT_HISTORY}`, data);

export const postEditNft = (data) =>
  api.createMultiPart(url.POST_NFTS_EDIT, data);

export const getNftDetailsSEO = (data) =>
  api.getWithNoToken(`${url.GET_NFTS_DETAILS_SEO}/${data}`);

export const getNftsInspireCategorySEO = (data) =>
  api.getWithNoToken(`${url.GET_NFTS_INSPIRE_CATEGORY_SEO}?category=${data}`);

export const postNftRating = (data) => api.create(url.POST_NFT_RATING, data);
export const getNftCheckDetails = (data) =>
  api.get(`${url.GET_NFT_CHECK_DETAILS}/${data}`);

export const getNftCheckName = (data) =>
  api.get(`${url.GET_NFTS_CHECK_NAME}/${data}`);

export const getUserMintCount = () => api.get(`${url.GET_NFT_USER_MINT_COUNT}`);

// Tutorials
export const getTutorialsList = (data) =>
  api.get(`${url.GET_TUTORIALS_LIST}?tags=${data}`);

export const getTutorialsList2 = (data) =>
  api.get(`${url.GET_TUTORIALS_LIST}?tags=${data}`);

export const postTutorialIncreaseViewCount = (data) =>
  api.create(`${url.POST_TUTORIALS_INCREASE_VIEW_COUNT}?videoId=${data}`);

//collection
export const getUserCollectionNfts = (data) =>
  api.get(`${url.GET_USER_COLLECTION_NFTS}/${data}`);

//Admincollection
export const postCollectionNftsDetail = (data) =>
  api.create(url.POST_COLLECTION_NFTS_DETAILS, data);

//GeneralSearch
export const postGeneralSearch = (data) =>
  api.create(url.POST_GENERAL_SEARCH, data);

//landingPage
export const getlandingPageWalletData = (data) =>
  api.get(`${url.GET_LANDING_PAGE_WALLET_DATA}?walletAddress=${data}`);

export const getUserBalanceWalletData = (data) =>
  api.get(`${url.GET_USER_SEED_TOKEN_BALANCE}?walletAddress=${data}`);

//?------------------------------------------------------------?//
//*-----------------------------------------------------------*//
//! ------ PLEASE PUT ADMIN PANEL APIS ABOVE THIS LINE, THANKS ------ !//

//TwitterFarm

export const getTwitterSettings = () => api_twitter_farm.get(url.GET_SETTINGS);

export const getTwitterMyPoints = () =>
  api_twitter_farm.get(url.GET_TWITTER_FARM_GET_MY_POINTS);

export const postTwitterMyActivities = (data) =>
  api_twitter_farm.create(url.POST_TWITTER_FARM_GET_MY_ACTIVITIES, data);

export const postTwitterMyPoints = (data) =>
  api_twitter_farm.create(url.POST_TWITTER_FARM_GET_MY_POINTS, data);

export const postTwitterMySeed = (data) =>
  api_twitter_farm.create(url.POST_TWITTER_FARM_GET_MY_SEED, data);

export const postTwitterAllUserPoints = (data) =>
  api_twitter_farm.create(url.POST_TWITTER_FARM_ALL_USER_POINTS, data);

export const postTwitterAllUserLikePointsV2 = (data) =>
  api_twitter_farm.create(url.POST_TWITTER_FARM_ALL_USER_LIKE_POINTS_V2, data);

export const getTwitterUpdateUserLeague = () =>
  api_twitter_farm.get(url.GET_TWITTER_FARM_UPDATE_USERS_LEAGUE);

export const postTwitterInvited = (data) =>
  api_twitter_farm.create(url.POST_TWITTER_FARM_INVITED, data);

//Withdrawals
export const postGetMyWithdrawals = (data) =>
  api_twitter_farm.create(url.POST_GET_MY_WITHDRAWALS, data);

export const postRequestWithdrawals = (data) =>
  api_twitter_farm.create(url.POST_REQUEST_WITHDRAWALS, data);

export const postCancelWithdrawals = (data) =>
  api_twitter_farm.create(url.POST_CANCEL_WITHDRAWALS, data);

//Stakes
export const postGetMyStakes = (data) =>
  api_twitter_farm.create(url.POST_GET_MY_STAKES, data);

export const postRequestWithdrawStakes = (data) =>
  api_twitter_farm.create(url.POST_REQUEST_STAKES, data);

export const postCancelStakes = (data) =>
  api_twitter_farm.create(url.POST_CANCEL_STAKES, data);

//CustomTask
export const postCustomTaskList = (data) =>
  api_twitter_farm.create(url.POST_CUSTOM_TASK_LIST, data);

export const postMyCustomTaskList = (data) =>
  api_twitter_farm.create(url.POST_MY_CUSTOM_TASK_LIST, data);

export const postUpdateCustomTask = (data) =>
  api_twitter_farm.createMultiPart(url.POST_TASK_UPDATE, data);
